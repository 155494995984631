import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

import {
  Article,
  ArticleHeader,
  ArticleDate,
  ArticleSection,
  ArticleAuthorInfo
} from "../../styledComponents/article"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
  SectionHeaderLedeDiv,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import EventList from "../../components/EventList"
import RelatedQtArticles from "../../components/QuantumTouch/relatedQuantumTouchAricles"
import ShareTools from "../../components/socialLinks"

const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)
const QtTreatmentsHealing = ({ data, location }) => {
  
  const pageTitle = data.allWpInformation.nodes[0].title
  const pageContent = data.allWpInformation.nodes[0].content
  const urlRegex = data.allWpInformation.nodes[0].uri.replace("information/%information_category%/", "quantum-touch/");
  const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}`+ urlRegex
  return (
    <Layout displayNavBorder location={location}>
      <SEO 
        title={pageTitle}
        description="The most frequently asked questions that I am asked about having a Quantum-Touch energy healing session."
        url="/quantum-touch/quantum-touch-treatments-and-healing"
      />
      <Article itemScope itemType="http://schema.org/Article">
        <ArticleHeader>
          <h1 itemProp="headline">{pageTitle}</h1>
          <ArticleDate offScreen dateTime={data.allWpInformation.nodes[0].dateGmt}>{data.allWpInformation.nodes[0].date}</ArticleDate>
          <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
        </ArticleHeader>

        {!!pageContent && (
          <ArticleSection itemProp="articleBody">
            {parse(pageContent)}
            <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={pageTitle}/>
          </ArticleSection>
        )}
        <RelatedQtArticles data={data.relatedArticles} /> 
      </Article>
      <BackgroundColourBlock number='2'>
      
        </BackgroundColourBlock>
    </Layout>
  )
}

export default QtTreatmentsHealing

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}, slug: {eq: "quantum-touch-treatments-and-healing"}}) {
      nodes {
        title
        excerpt
        content
        uri
        slug
        date(formatString: "MMMM DD, YYYY")
        dateGmt
      }
    }
   
    relatedArticles : allWpInformation(filter: {tags: {nodes: {elemMatch: {slug: {eq: "quantum-touch"}}}}}) {
      nodes {
        title
        slug
        uri
      }
    }
  }
`
